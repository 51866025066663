<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../service/MethodService";
import { useRouter, useRoute } from "vue-router";
import DataForm from "./dataUseCase";
import toastr from "toastr";
import LanguaService from "../../service/LanguageService";
import { langWeb } from "../../stores/lang";
const router = useRouter();
const route = useRoute();
import { datePicker } from "../../stores/datePicker";
const storeDatePicker = datePicker();
import { listMenu } from "../../stores/ListMenu";
const storeListMenu = listMenu();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase()); // đa ngôn ngữ cho thông báo swal, toastr
import { marked } from "marked";

import ListColumnsName from "../../components/ListColumnsName.vue";
import ListColumnsFilter from "../../components/ListColumnsFilter";
import ListPagination from "../../components/ListPagination.vue";
import ListWarningData from "../../components/ListWarningData.vue";
import { abortAPI } from "@/stores/abortAPI";

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const loadingData = ref(false);
const loadingBtn = ref(false);
const isClickDownload = ref(true); // nút download đang hiển thị
const isBtnLoading = ref(false); // nút Đang download
const idRequestDownload = ref("");
const UCID = ref();
const IdCardUC = ref();
const idCardItem = ref();
const dataTable = reactive({
  data: [],
  columnsOptionHide: [],
  selectColumnsToFilter: [],
  total: 0,
  totalPages: 0,
  listServices: [],
  servicesSelect: [],
});
const isShowVLAN = ref(true);
const isShowMAC = ref(true);
const isShowACCOUNT = ref(false); // mặc định ẩn cột account
const isShowDATE = ref(true);
const isShowCOUNT_UC = ref(false); // mặc định ẩn cột count_uc
const isShowDELAYCAP2UE = ref(true);
const isShowTRAFFIC = ref(true);
const isShowMAX_SPEED = ref(false); // mặc định ẩn cột max_speed
const isShowBEHAVIOR = ref(true);
const isShowSERVICE = ref(false); // mặc định ẩn cột service
const isShowNOTE = ref(true);
const isShowSL = ref(true);
const isShowPRIORITY = ref(true);
const isShowCOUNT_DEVICE = ref(true);
const isShowBAD_TIME = ref(true);
const inputSearch = ref("");
const nameColumnToFilter = ref("");
const valueColumnToFilter = ref("");
const typeColumnToFilter = ref("input");
const countCloseListFilter = ref(0);
const countCloseListColumnsName = ref(0);
const countCloseWarningData = ref(0);
const ucTypeValue = ref(1); // DelayCap2UE: 1; Behavior: 2
const keyListWarningData = ref(0);
const keyListColumnsFilter = ref(0);
const nameFilterURL = ref("");
const isDisabledPageNext = ref(false);
const isDisabledPagePrev = ref(true);
const storeAbortAPI = abortAPI();
const abortController = new AbortController();

const markdownToHTML = (text) => {
  const html = marked(text);
  return html;
};
const getUCIDByCode = async (codeName) => {
  try {
    const response = await mushroom.usecase.listAsync({
      filters: ["code=" + codeName],
    });
    UCID.value = response.result[0].id;
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getIdCardByUCID = async (id) => {
  try {
    const response = await mushroom.card.listAsync({
      filters: ["uc_id=" + id],
    });
    if (response.result) IdCardUC.value = response.result[0].id;
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getIdCardItem = async (id) => {
  try {
    const response = await mushroom.card_item.listAsync({
      filters: ["card_id=" + id],
    });
    if (response.result) idCardItem.value = response.result[0].id;
    dataTable.selectColumnsToFilter = [];
    dataTable.columnsOptionHide = [];
    response.result[0].table.format.forEach((item) => {
      if (item.filter.is_allowed)
        dataTable.selectColumnsToFilter.push({
          value: item.code,
          label: item.title_name,
          type: item.filter?.input_type ? item.filter.input_type : "input",
        });

      if (item.code && item.field_name !== 'Service' && item.field_name !== 'Max_Speed') {
        dataTable.columnsOptionHide.push({
          value: item.code,
          label: item.title_name,
          field_name: item.field_name,
        });
      }
    });
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getDataSource = async () => {
  loadingData.value = true;

  let bodyData = {
    card_item_id: idCardItem.value,
    probe_id: localStorage.pid,
    extra: {
      uc_type: tableRules.uc_type,
    },
    order: tableRules.sort,
    limit: tableRules.limit,
    offset: tableRules.offset,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
  };

  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    bodyData.extra[tableRules.filterKey] = tableRules.filterValue.trim();

  let sortURL = "";
  let filterURL = "";
  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    filterURL = tableRules.filterKey + ":" + tableRules.filterValue.trim();
  for (const [key, value] of Object.entries(tableRules.sort)) {
    value == 1 ? (sortURL = key) : (sortURL = "-" + key);
  }

  const search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );
  queryForPush.uc_type = tableRules.uc_type;
  queryForPush.$sort = sortURL;
  queryForPush.$colfilter = filterURL;

  router
    .replace({
      name: "UsecaseGeneral",
      query: queryForPush,
    })
    .catch(() => {});
  try {
    const resDataSource = await mushroom.probe_data.getDatasourceAsync({
      body: bodyData,
      settings: {
        abortController
      }
    });
    dataTable.data = [];
    dataTable.total = resDataSource.result.total;
    dataTable.totalPages = Math.ceil(
      resDataSource.result.total / tableRules.limit
    );
    resDataSource.result.data.forEach((item) => {
      let obj = {};
      item.forEach((item1, index1) => {
        if (
          !resDataSource.result.columns[index1] ||
          !resDataSource.result.columns[index1].code
        )
          return;
        obj[resDataSource.result.columns[index1].code] = item1; // xem lại chỗ này có .code hay không
      });
      dataTable.data.push(obj);
    });
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loadingData.value = false;
  }
};

const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  const search = location.search.substring(1);
  const data = search ? JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) }) : '';

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "uc",
    uc_id: UCID.value,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (e) {
    console.error("Có lỗi: %o", e);
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(e.detail);
  }
};
const checkStatus = async () => {
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_data.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};
const showColumn = (nameColumn) => {
  if (nameColumn == "vlan_id_outer") isShowVLAN.value = true;
  if (nameColumn == "mac_cust") isShowMAC.value = true;
  if (nameColumn == "acc_name") isShowACCOUNT.value = true;
  if (nameColumn == "date") isShowDATE.value = true;
  if (nameColumn == "count_uc") isShowCOUNT_UC.value = true;
  if (nameColumn == "delayCap2Ue") isShowDELAYCAP2UE.value = true;
  if (nameColumn == "traffic_total") isShowTRAFFIC.value = true;
  if (nameColumn == "max_speed") isShowMAX_SPEED.value = true;
  if (nameColumn == "behavior") isShowBEHAVIOR.value = true;
  if (nameColumn == "service_alert") isShowSERVICE.value = true;
  if (nameColumn == "count_device") isShowCOUNT_DEVICE.value = true;
  if (nameColumn == "note") isShowNOTE.value = true;
  if (nameColumn == "priority") isShowPRIORITY.value = true;
  if (nameColumn == "sl") isShowSL.value = true;
  if(nameColumn == 'bad_wifi_time') isShowBAD_TIME.value = true;
};
const hideColumn = (nameColumn) => {
  if (nameColumn == "vlan_id_outer") isShowVLAN.value = false;
  if (nameColumn == "mac_cust") isShowMAC.value = false;
  if (nameColumn == "acc_name") isShowACCOUNT.value = false;
  if (nameColumn == "date") isShowDATE.value = false;
  if (nameColumn == "count_uc") isShowCOUNT_UC.value = false;
  if (nameColumn == "delayCap2Ue") isShowDELAYCAP2UE.value = false;
  if (nameColumn == "traffic_total") isShowTRAFFIC.value = false;
  if (nameColumn == "max_speed") isShowMAX_SPEED.value = false;
  if (nameColumn == "behavior") isShowBEHAVIOR.value = false;
  if (nameColumn == "service_alert") isShowSERVICE.value = false;
  if (nameColumn == "count_device") isShowCOUNT_DEVICE.value = false;
  if (nameColumn == "note") isShowNOTE.value = false;
  if (nameColumn == "priority") isShowPRIORITY.value = false;
  if (nameColumn == "sl") isShowSL.value = false;
  if(nameColumn == 'bad_wifi_time') isShowBAD_TIME.value = false;
};
const nameColumnToFilterPlacehoder = (nameColumn, valueColumn, typeColumn) => {
  nameColumnToFilter.value = nameColumn;
  valueColumnToFilter.value = valueColumn;
  typeColumnToFilter.value = typeColumn ? typeColumn : "input";
  if (!tableRules.filterKey) inputSearch.value = "";
  if(typeColumn === 'number') inputSearch.value = tableRules.filterValue.toString().slice(2);
  if(tableRules.filterValue && typeColumn !== 'number') inputSearch.value = tableRules.filterValue;
};

const closeListFilter = () => {
  countCloseListFilter.value += 1;
};
const closeWarningData = () => {
  countCloseWarningData.value += 1;
};
const closeListColumnsName = () => {
  countCloseListColumnsName.value += 1;
};
const changePageFromListPagination = (pageSize) => {
  fn_tableSizeChange(pageSize);
};
const fn_tablePrevClick = () => {
  isDisabledPageNext.value = false;
  const pagePrev = tableRules.page - 1;
  if (pagePrev == 1) {
    isDisabledPagePrev.value = true;
  }
  tableRules.offset = (pagePrev - 1) * tableRules.limit;
  fn_tableChangeOffset(pagePrev);
};
const fn_tableNextClick = () => {
  isDisabledPagePrev.value = false;
  const pageNext = tableRules.page + 1;
  if (pageNext == dataTable.totalPages) {
    isDisabledPageNext.value = true;
  }
  tableRules.offset = (pageNext - 1) * tableRules.limit;
  fn_tableChangeOffset(pageNext);
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  page == 1
    ? (isDisabledPagePrev.value = true)
    : (isDisabledPagePrev.value = false);
  page == dataTable.totalPages
    ? (isDisabledPageNext.value = true)
    : (isDisabledPageNext.value = false);
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = async (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  await getDataSource();
};

const filterData = async () => {
  const keySearch =
    valueColumnToFilter.value || dataTable.selectColumnsToFilter[0].value;
  const typeSearch = typeColumnToFilter.value;
  let valueSearch = "";
  switch (typeSearch) {
    case "datepicker":
      valueSearch = MethodService.formatDateYYYYMMDD(inputSearch.value);
      break;
    case "tag_input":
      valueSearch = MethodService.formatFilterObjToStr(inputSearch.value);
      break;
    default:
      valueSearch = inputSearch.value;
      break;
  }
  tableRules.filterKey = keySearch;
  tableRules.offset = 0;
  if (typeSearch == "number" && inputSearch.value !== "") {
    if (valueSearch.match(/^[0-9.]+$/)) {
      valueSearch = `${keySearch === 'priority' ? "<=" : ">="}` + valueSearch;
    } else if (!valueSearch.match(/^([><]?=?)[0-9.]+$/)) {
      toastr.warning(LanguaService[lang.value]?.toastr_wrong_input_search ?? "toastr_wrong_input_search");
      return;
    }
  }

  tableRules.filterValue = valueSearch;
  await getDataSource();
};
const fn_tableSortChange = async (column) => {
  let obj = {};
  dataTable.columnsOptionHide.forEach((item) => {
    if (item.value === column.prop) column.prop = item.field_name;
  });
  if (column.order == "ascending") {
    obj[column.prop] = 1;
    tableRules.sort = obj;
  } else if (column.order == "descending") {
    obj[column.prop] = -1;
    tableRules.sort = obj;
  } else {
    tableRules.sort = "";
  }
  await getDataSource();
};

const changeWarningData = async (nameColumn, valueColumn) => {
  tableRules.uc_type = valueColumn;
  tableRules.offset = 0;
  await getDataSource();
};
const renderCountDevice = (data) => {
  let newData = undefined;
  if (!data.includes("value")) {
    return data;
  } else {
    newData = JSON.parse(data);
    return newData.value;
  }
};
const renderCountDeviceTooltip = (data) => {
  let newData = undefined;
  if (!data.includes("value")) {
    return data;
  } else {
    newData = JSON.parse(data);
    return newData.tooltip;
  }
};
const getInfoURL = () => {
  if (route.query.$sort) {
    let strSort = route.query.$sort;
    let objSort = {};
    if (strSort.includes("-")) {
      let keySort = strSort.toString().replace("-", "");
      objSort[keySort] = -1;
      tableRules.sort = objSort;
    } else {
      objSort[strSort] = 1;
      tableRules.sort = objSort;
    }
    tableRules.defaultSort[0] = tableRules.sort;
    if (route.query.$sort.indexOf("-") != -1) {
      tableRules.defaultSort[1] = "descending";
    } else {
      tableRules.defaultSort[1] = "ascending";
    }
  }
  if (route.query.$colfilter) {
    let strFilterURL = route.query.$colfilter;
    let index = strFilterURL.toString().indexOf(":");
    tableRules.filterKey = strFilterURL.toString().slice(0, index);
    tableRules.filterValue = strFilterURL.toString().slice(index + 1);
    nameFilterURL.value = tableRules.filterKey;
  }
  if (route.query.uc_type) {
    tableRules.uc_type = route.query.uc_type;
    ucTypeValue.value = route.query.uc_type;
  }
};

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    tableRules.offset = 0;
    getInfoURL();
    await getUCIDByCode("tong_hop");
    await getIdCardByUCID(UCID.value);
    await getIdCardItem(IdCardUC.value);
    await getDataSource();
    keyListWarningData.value += 1;
    keyListColumnsFilter.value += 1;
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  getInfoURL();
  tableRules.sort = { DelayCap2UE: -1 };
  tableRules.defaultSort = ["DelayCap2UE", "descending"];

  dataTable.listServices = [];
  await storeListMenu.getListMenuService();
  await getUCIDByCode("tong_hop");
  await getIdCardByUCID(UCID.value);
  await getIdCardItem(IdCardUC.value);
  await getDataSource();
  if (storeListMenu.showListMenuService.length > 0) {
    storeListMenu.showListMenuService.forEach((item) => {
      dataTable.listServices.push({
        value: item.id,
        label: item.name,
      });
    });
  }
  keyListWarningData.value += 1;
  keyListColumnsFilter.value += 1;
});
</script>

<template>
  <Layout>
    <div class="dashboard-all">
      <div class="dataUC">
        <div class="dataUC__header">
          <div class="dataUC__header-left">
            <div class="dataUC__header__pagination">
              <list-pagination
                :listPagesCount="tableRules.lengthMenu"
                :pageSize="tableRules.limit"
                @changePageFromListPagination="changePageFromListPagination"
              >
              </list-pagination>
            </div>
            <div class="dataUC__header__showCol">
              <list-columns-name
                :listColumns="dataTable.columnsOptionHide"
                :countCloseListColumnsName="countCloseListColumnsName"
                @showColumn="showColumn"
                @hideColumn="hideColumn"
                @closeListFilter="closeListFilter"
                @closeWarningData="closeWarningData"
              ></list-columns-name>
            </div>
            <div class="dataUC__header__warning">
              <list-warning-data
                :listColumnsWarning="DataForm.warningData"
                :ucTypeValue="ucTypeValue"
                :countCloseWarningData="countCloseWarningData"
                @changeWarningData="changeWarningData"
                @closeListColumnsName="closeListColumnsName"
                @closeListFilter="closeListFilter"
                :key="keyListWarningData"
              ></list-warning-data>
            </div>
          </div>
          <div class="dataUC__header-mid">
            <div class="dataUC__header__filter">
              <list-columns-filter
                :listColumns="dataTable.selectColumnsToFilter"
                :countCloseListFilter="countCloseListFilter"
                :nameFilterURL="nameFilterURL"
                @nameColumnToFilter="nameColumnToFilterPlacehoder"
                @closeListColumnsName="closeListColumnsName"
                @closeWarningData="closeWarningData"
                :key="keyListColumnsFilter"
              ></list-columns-filter>
              <div class="dataUC__header__filter-icon">
                <span class="icon is-small is-left">
                  <img
                    v-if="typeColumnToFilter == 'number' && valueColumnToFilter === 'priority'"
                    src="../../assets/images/chart/nho_hoac_bang.svg"
                    height="18"
                  />
                  <img
                    src="../../assets/images/chart/hon_hoac_bang.svg"
                    height="18"
                    v-if="typeColumnToFilter == 'number' && valueColumnToFilter !== 'priority'" />
                  <svg
                    v-if="typeColumnToFilter !== 'number' && valueColumnToFilter !== 'priority'"
                    class="svg-inline--fa fa-search"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    ></path></svg
                ></span>
                <div
                  v-if="typeColumnToFilter == 'datepicker'"
                  class="dataUC__header__filter-datePicker"
                >
                  <el-date-picker
                    v-model="inputSearch"
                    type="date"
                    :placeholder="`${$t('t_input_column_search_value')} ${nameColumnToFilter}`"
                    :prefix-icon="11"
                    format="YYYY-MM-DD"
                    @change="filterData"
                  />
                </div>
                <div
                  v-if="typeColumnToFilter == 'number'"
                  class="dataUC__header__filter-datePicker"
                >
                  <el-input
                    v-model="inputSearch"
                    class="w-100"
                    :placeholder="`${$t('t_input_column_search_value')} ${nameColumnToFilter}`"
                    @change="filterData"
                  />
                </div>
                <div
                  v-if="typeColumnToFilter == 'tag_input'"
                  class="dataUC__header__filter-datePicker"
                >
                  <el-select
                    v-model="inputSearch"
                    multiple
                    :placeholder="`${$t('t_input_column_search_value')} ${nameColumnToFilter}`"
                    @change="filterData"
                  >
                    <el-option
                      v-for="item in dataTable.listServices"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>

                <el-input
                  v-if="
                    typeColumnToFilter !== 'number' &&
                    typeColumnToFilter !== 'tag_input' &&
                    typeColumnToFilter !== 'datepicker'
                  "
                  v-model="inputSearch"
                  class="w-100"
                  :placeholder="`${$t('t_input_column_search_value')} ${nameColumnToFilter}`"
                  @change="filterData"
                />
              </div>
            </div>
          </div>
          <div class="dataUC__header-right">
            <div>
              <button
                v-if="isClickDownload"
                @click="downloadCard"
                type="button"
                class="button-download"
              >
                Excel
                <span class="icon is-small"
                  ><svg
                    class="svg-inline--fa fa-file-excel"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-excel"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                    ></path></svg
                ></span>
              </button>
              <el-button
                v-if="isBtnLoading"
                type="button"
                :loading="loadingBtn"
              >
              {{ $t('t-loading-get-data') }}...
              </el-button>
            </div>
          </div>
        </div>
        <div class="dataUC__body">
          <el-table
            :data="dataTable.data"
            v-loading="loadingData"
            header-row-class-name="headerUC"
            @sort-change="fn_tableSortChange"
            :default-sort="{ prop: 'delayCap2Ue', order: 'descending' }"
            :empty-text="$t('t-no-data')"
          >
            <el-table-column label="#" width="53" align="center">
              <template #default="scope">
                <div>
                  {{ scope.$index + tableRules.offset + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="vlan_id_outer"
              label="VLAN"
              min-width="60"
              align="center"
              class-name="colStyle"
              v-if="isShowVLAN"
            >
              <template #default="scope">
                <span
                  class="colStyle__bg-neonblue"
                  v-if="
                    scope.row.vlan_id_outer !== null &&
                    scope.row.vlan_id_outer !== undefined &&
                    scope.row.vlan_id_outer !== '' &&
                    scope.row.vlan_id_outer !== '-'
                  "
                  >{{ scope.row.vlan_id_outer }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mac_cust"
              label="MAC"
              min-width="130"
              align="center"
              v-if="isShowMAC"
              class-name="colStyle"
              sortable
            >
              <template #default="scope">
                <span v-html="MethodService.fnRenderMAC(scope.row)"></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="acc_name"
              label="Account"
              min-width="105"
              align="center"
              class-name="colStyle"
              v-if="isShowACCOUNT"
            >
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.acc_name !== null &&
                    scope.row.acc_name !== undefined &&
                    scope.row.acc_name !== '' &&
                    scope.row.acc_name !== '-'
                  "
                  >{{ scope.row.acc_name }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              label="Date"
              min-width="92"
              align="center"
              class-name="colStyle"
              v-if="isShowDATE"
              sortable
            >
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.date !== null &&
                    scope.row.date !== undefined &&
                    scope.row.date !== '' &&
                    scope.row.date !== '-'
                  "
                  >{{ scope.row.date }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="priority"
              label="Pri"
              width="65"
              align="center"
              class-name="colStyle"
              v-if="isShowPRIORITY"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Priority"
                  placement="top"
                >
                  <span class="header-tooltip">Pri</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  v-if="
                    scope.row.priority !== null &&
                    scope.row.priority !== undefined &&
                    scope.row.priority !== '' &&
                    scope.row.priority !== '-'
                  "
                  class="colStyle__service-color"
                  :class="{ padding08: Number(scope.row.priority) < 10 }"
                  >{{ scope.row.priority }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sl"
              label="SL"
              width="55"
              align="center"
              class-name="colStyle"
              v-if="isShowSL"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number of Days Needing Attention"
                  placement="top"
                >
                  <span class="header-tooltip">RD</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  v-if="
                    scope.row.sl !== null &&
                    scope.row.sl !== undefined &&
                    scope.row.sl !== '' &&
                    scope.row.sl !== '-'
                  "
                  class="colStyle__service-color"
                  :class="{ padding08: Number(scope.row.sl) < 10 }"
                  >{{ scope.row.sl }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="count_uc"
              label="UC(s)"
              width="85"
              align="center"
              class-name="colStyle"
              v-if="isShowCOUNT_UC"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number or Use Case"
                  placement="top"
                >
                  <span class="header-tooltip">UC(s)</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  v-if="
                    scope.row.count_uc !== null &&
                    scope.row.count_uc !== undefined &&
                    scope.row.count_uc !== '' &&
                    scope.row.count_uc !== '-'
                  "
                  class="colStyle__service-color"
                  :class="{ padding08: Number(scope.row.count_uc) < 10 }"
                  >{{ scope.row.count_uc }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="delayCap2Ue"
              label="UE-CAP"
              width="92"
              align="center"
              class-name="colStyle"
              v-if="isShowDELAYCAP2UE"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Latency UE - Capture Point (ms)"
                  placement="top"
                >
                  <span class="header-tooltip">UE-CAP</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__treq"
                  v-if="
                    scope.row.delayCap2Ue !== null &&
                    scope.row.delayCap2Ue !== undefined &&
                    scope.row.delayCap2Ue !== '' &&
                    scope.row.delayCap2Ue !== '-'
                  "
                  >{{ scope.row.delayCap2Ue }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="traffic_total"
              label="Data(GB)"
              min-width="80"
              align="center"
              class-name="colStyle"
              v-if="isShowTRAFFIC"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Total Data Traffic used by customers in GB"
                  placement="top"
                >
                  <span class="header-tooltip">Data(GB)</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.traffic_total !== null &&
                    scope.row.traffic_total !== undefined &&
                    scope.row.traffic_total !== '' &&
                    scope.row.traffic_total !== '-'
                  "
                  >{{ scope.row.traffic_total }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="max_speed"
              label="Max_Speed"
              min-width="100"
              align="center"
              class-name="colStyle"
              v-if="isShowMAX_SPEED"
            >
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.max_speed !== null &&
                    scope.row.max_speed !== undefined &&
                    scope.row.max_speed !== '' &&
                    scope.row.max_speed !== '-'
                  "
                  >{{ scope.row.max_speed }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="behavior"
              label="Behavior"
              min-width="180"
              align="center"
              class-name="colStyle"
              v-if="isShowBEHAVIOR"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Alerts according to customer behavior. Example: Use Speedtest multiple times"
                  placement="top"
                >
                  <span class="header-tooltip">Behavior</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__red"
                  v-html="MethodService.fnRenderBehavior(scope.row)"
                ></span> </template
            ></el-table-column>
            <el-table-column
              prop="service_alert"
              label="Service"
              min-width="180"
              align="center"
              class-name="colStyle"
              v-if="isShowSERVICE"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Alerts according to service quality"
                  placement="top"
                >
                  <span class="header-tooltip">Service</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__default"
                  v-html="MethodService.fnRenderServiceCol(scope.row)"
                ></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="count_device"
              label="Dev"
              width="65"
              align="center"
              class-name="colStyle"
              v-if="isShowCOUNT_DEVICE"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number of detected UEs"
                  placement="top"
                >
                  <span class="header-tooltip">Dev</span>
                </el-tooltip>
              </template>
              <!-- :class="{ padding08: Number(scope.row.count_device) < 10 }" -->

              <template #default="scope">
                <span v-if="scope.row.count_device.includes('value')">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="renderCountDeviceTooltip(scope.row.count_device)"
                    placement="top"
                    v-if="
                      scope.row.count_device !== null &&
                      scope.row.count_device !== undefined &&
                      scope.row.count_device !== '' &&
                      scope.row.count_device !== '-'
                    "
                  >
                    <span class="colStyle__bg-green">{{
                      renderCountDevice(scope.row.count_device)
                    }}</span>
                  </el-tooltip>
                </span>
                <span v-if="!scope.row.count_device.includes('value')">
                  <span
                    v-if="
                      scope.row.count_device !== null &&
                      scope.row.count_device !== undefined &&
                      scope.row.count_device !== '' &&
                      scope.row.count_device !== '-'
                    "
                  >
                    <span class="colStyle__bg-green">{{
                      renderCountDevice(scope.row.count_device)
                    }}</span>
                  </span>
                </span>

                <span
                  v-if="
                    scope.row.count_device === null ||
                    scope.row.count_device === undefined ||
                    scope.row.count_device === '' ||
                    scope.row.count_device === '-'
                  "
                  class="none-dataUC"
                  >-</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="bad_wifi_time"
              label="BTime"
              width="65"
              align="center"
              class-name="colStyle"
              v-if="isShowBAD_TIME"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Wifi Bad Time (min)"
                  placement="top"
                >
                  <span class="header-tooltip">BTime</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.bad_wifi_time !== null &&
                    scope.row.bad_wifi_time !== undefined &&
                    scope.row.bad_wifi_time !== '' &&
                    scope.row.bad_wifi_time !== '-'
                  "
                  >{{ scope.row.bad_wifi_time }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="note"
              label="Note"
              min-width="300"
              align="center"
              class-name="colStyle"
              v-if="isShowNOTE"
            >
              <!-- <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number of detected UEs"
                  placement="top"
                >
                  <span class="header-tooltip">Devices</span>
                </el-tooltip>
              </template> -->
              <template #default="scope">
                <span
                  class="none-dataUC markdown"
                  style="text-align: start; display: block"
                  v-if="
                    scope.row.note !== null &&
                    scope.row.note !== undefined &&
                    scope.row.note !== '' &&
                    scope.row.note !== '-'
                  "
                  v-html="markdownToHTML(scope.row.note)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dataUC__footer" v-if="dataTable.data.length > 0">
          <div class="dataUC__footer-total">
            {{
              `Từ ${tableRules.offset + 1} tới ${
                tableRules.offset + tableRules.limit
              } của ${dataTable.total} bản ghi`
            }}
          </div>
          <div class="dataUC__footer-pagination">
            <el-pagination
              small
              background
              layout="pager"
              v-model:currentPage="tableRules.page"
              v-model:page-size="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              :total="dataTable.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
            />
            <button
              type="button"
              class="dataUC__footer-pagination-previous"
              @click="fn_tablePrevClick()"
              :disabled="isDisabledPagePrev || dataTable.totalPages == 1"
            >
              Previous
            </button>
            <button
              type="button"
              class="dataUC__footer-pagination-next"
              @click="fn_tableNextClick()"
              :disabled="isDisabledPageNext || dataTable.totalPages == 1"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./usecase.scss" />
